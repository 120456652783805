import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@src/app/core/services';
import { Observable } from 'rxjs';
import { ListDetails } from '../types/option.type';
import { Professional, ProfessionalDetail } from '../types/professionals.type';

@Injectable({
  providedIn: 'root',
})
export class ProfessionalListingRepository {
  public constructor(
    private readonly httpClient: HttpClient,
    private localStorageService: LocalStorageService,
  ) {}

  public getProfessionals(params: HttpParams): Observable<ListDetails<Professional>> {
    const user = this.localStorageService.get('user');

    const apiUrl = user ? 'all-professionals' : 'all-professionals-public';
    return this.httpClient.get<ListDetails<Professional>>(
      `/professional/profile/${apiUrl}`,
      {
        params,
      },
    );
  }

  public getFavorites(params: HttpParams): Observable<ListDetails<Professional>> {
    return this.httpClient.get<ListDetails<Professional>>('/favorite-professionals', {
      params,
    });
  }

  public getProfessional(id: number): Observable<ProfessionalDetail> {
    const user = this.localStorageService.get('user');
    const apiUrl = user ? `/professional/profile/${id}` : `/professional/profile/${id}/public`;

    return this.httpClient.get<ProfessionalDetail>(apiUrl);
  }

  postFavoriteJob(payload: { professionalProfileId: number }) {
    return this.httpClient.post<{ message: string }>('/favorite-professionals/favorite', payload);
  }

  deleteFromFavorite(payload: { professionalProfileId: number }) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: payload,
    };
    return this.httpClient.delete<{ message: string }>(
      `/favorite-professionals/un-favorite`,
      options,
    );
  }
}
